<template>
  <div class="width-100 height-100 max_box" id="boxesGroup1">
    <div class="audio_box none">
      <audio src="audio/zhongxin2/Loveless.mp3" ref="bgAudio"></audio>
      <audio ref="audio1" src="audio/zhongxin2/1.mp3"></audio>
      <audio ref="audio2" src="audio/zhongxin2/2.mp3"></audio>
      <audio ref="audio3" src="audio/zhongxin2/3.mp3"></audio>
      <audio ref="audio4" src="audio/zhongxin2/4.mp3"></audio>
      <audio ref="audio4_2" src="audio/zhongxin2/4_2.mp3"></audio>
      <audio ref="audio5" src="audio/zhongxin2/5.mp3"></audio>
      <audio ref="audio6" src="audio/zhongxin2/6.mp3"></audio>
      <audio ref="audio7" src="audio/zhongxin2/7.mp3"></audio>
      <audio ref="audio8" src="audio/zhongxin2/8.mp3"></audio>
      <audio ref="audio9" src="audio/zhongxin2/9.mp3"></audio>
      <audio ref="audio11" src="audio/zhongxin2/11.mp3"></audio>
      <audio ref="audio12" src="audio/zhongxin2/10.mp3"></audio>
       <audio ref="audio13" src="audio/zhongxin2/15.mp3"></audio>
       <audio ref="audio14" src="audio/zhongxin2/16.mp3"></audio>
       <audio ref="audio15" src="audio/zhongxin2/17.mp3"></audio>
      <audio ref="audio16" src="audio/zhongxin2/11.mp3"></audio>
      <audio ref="audio17" src="audio/zhongxin2/12.mp3"></audio>
      <audio ref="audio18" src="audio/zhongxin2/13.mp3"></audio>
      <audio ref="audio19" src="audio/zhongxin2/14.mp3"></audio>
    </div>
    <div class="card1 card animate__animated" v-show="currentIndex == 1">
      <div class="translate_text" id="text1">
        本视频内容涉及理财产品，风险等级为<span class="color-A70B12 letter"
          >R3（中风险）</span
        >，适合<span class="color-A70B12 letter">C3及以上</span>
        投资者，基金投资有风险，入市需谨慎。
      </div>
    </div>
    <div class="card2 card animate__animated" v-show="currentIndex == 2">
      <div class="fofimg_box">
        <img src="../../assets/img/zx_fof.png" alt="" />
        <div class="squre squrel">
          <img src="../../assets/img/zxsqurel.png" alt="" />
        </div>
        <div class="squre squrer">
          <img src="../../assets/img/zxsqurer.png" alt="" />
        </div>
      </div>
      <div class="logo_box">
        <img src="../../assets/img/zxlogo.png" alt="" />
        <div class="logo_re">
            <img src="../../assets/img/zhongxin/zxlogo2.png" alt="">
        </div>
      </div>
    </div>
    <div class="card3 card animate__animated" v-show="currentIndex == 3">
      <div class="header none">
        <span class="h_l">公募基金数量</span><span class="h_r">＞<i id="number_card3">13000</i>只</span>
      </div>
      <div class="chart none" id="chart1"></div>
    </div>
    <div class="card4 card animate__animated" v-show="currentIndex == 4">
      <div class="bg_box">
        <img class="bg_img" src="../../assets/img/zhongxin/zxbg1.png" alt="" />
        <span>保险基金</span>
        <span>二级债基</span>
        <span>指数型</span>
        <span>股票基金</span>
        <span>偏股混合型</span>
        <span>香港互认基金</span>
        <span>Reits</span>
        <span>QDII</span>
        <span>货币基金</span>
        <span>普通股票型</span>
        <span>偏债混合型</span>
        <span>商品型</span>
        <span>公司型基金</span>
        <span>信托基金</span>
        <span>货币基金</span>
        <span>纯债基金</span>
        <span>平衡型基金</span>
      </div>
      <div class="sign">
        <img src="../../assets/img/zhongxin/zxsign6.png" alt="" />
      </div>
    </div>
    <div class="card5 card animate__animated" v-show="currentIndex == 5">
      <div class="header">市场变化导致基金业绩分化</div>
      <div class="content">
        <div class="left none">
          <div class="left_content">
            <div class="top">近三年收益率前10</div>
            <div class="chart_box">
              <div class="chart" id="chart5"></div>
              <ul>
                <li v-for="(item, index) in card5LiData1" :key="index">
                  <span :id="'li' + index">{{ item }}</span
                  >%
                </li>
              </ul>
            </div>
          </div>
          <div class="bottom">
            <span>前10%平均</span>
            <span><i id="number_per1">289.04</i>%</span>
          </div>
        </div>
        <div class="right none">
          <div class="right_content">
            <div class="top">近三年收益率后10</div>
            <div class="chart_box">
              <div class="chart" id="chart6"></div>
              <ul>
                <li v-for="(item, index) in card5LiData2" :key="index">
                  <span :id="'li2' + index">{{ item }}</span
                  >%
                </li>
              </ul>
            </div>
          </div>

          <div class="bottom">
            <span>后10%平均</span>
            <span><i id="number_per2">51.43</i>%</span>
          </div>
        </div>
      </div>
      <div class="sign sign1">
        <img src="../../assets/img/zhongxin/zxsign5.png" alt="" />
      </div>
    </div>
    <div class="card6 card animate__animated" v-show="currentIndex == 6">
      <span class="main_span">择时为王</span>
      <span class="right_span none">情绪性</span>
      <span class="left_span none">专业性</span>
      <span class="right_span none">择时困难</span>
      <span class="left_span none">紧跟市场</span>
      <span class="left_span none">动态跟踪</span>
      <span class="right_span none">预测走势</span>
      <span class="left_span none">舍不得卖</span>
      <span class="right_span none">耗费精力</span>
    </div>
    <div class="card7 card animate__animated" v-show="currentIndex == 7">
      <div class="header">公募基金募集规模与万得全A指数走势</div>
      <div class="chart_box">
        <div class="chart none" id="chart2"></div>
        <div class="tips none">
          <div class="point"></div>
          <div class="line"></div>
          <div class="text">高点买入基金可能造成本金或机会损失</div>
          <div class="point2"></div>
        </div>
      </div>
      <div class="sign">
        <img src="../../assets/img/zhongxin/zxsign7.png" alt="" />
      </div>
    </div>
    <div class="card8 card animate__animated" v-show="currentIndex == 8">
      <div class="translate_text" id="text7">我该如何选择</div>
    </div>
    <div class="card9 card animate__animated" v-show="currentIndex == 9">
      <div class="bg_box">
        <div class="translate_text text">xx证券财富优选一年持有FOF</div>
      </div>
    </div>
    <div class="card10 card animate__animated" v-show="currentIndex == 10">
      <div class="bg_box">
        <div class="translate_text text1 text">
          让更专业的人帮你选优质基金、管理基金
          <div class="hg none">
            <img src="../../assets/img/zhongxin/zxhg.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="card11 card animate__animated" v-show="currentIndex == 11">
      <div class="header">
        xx证券财富优选一年持有期混合型基金中基金(FOF)集合资产管理计划A类
      </div>
      <div class="tab_box none">
        <div class="tab">
          <span>900012</span>
          <span>FOF-偏股</span>
          <span>中高风险</span>
          <span>锁定期1年</span>
        </div>
      </div>
      <div class="line none"></div>
      <div class="article_box none">
        <div class="article">
          <div class="left">
            <p>
              <span><i id="number1">10.39</i>%</span>
              <span>成立以来涨幅</span>
            </p>
            <p>
              <span><i id="number2">0.58</i>%</span>
              <span>日涨跌幅</span>
            </p>
            <p>
              <span><i id="number3">1.5495</i></span>
              <span>净值12-02涨跌幅</span>
            </p>
          </div>
          <div class="right">
            <p>
              <span>资产规模</span>
              <span><i id="number4">5939.50</i>万</span>
            </p>
            <p>
              <span>成立日期</span>
              <span>2021-07-22</span>
            </p>
            <p>
              <span>基金管理人</span>
              <span>xx证券股份有限公司</span>
            </p>
            <p>
              <span>xx证券股份有限公司</span>
              <span>xx银行股份有限公司</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card12 card animate__animated" v-show="currentIndex == 12">
      <div class="content_box">
        <div class="title animate__animated none">
          xx证券财富优选一年持有（原xx证券基金精选）
        </div>
        <div id="chart12"></div>
      </div>
    </div>
    <div class="card13 card animate__animated" v-show="currentIndex == 13">
      <div class="header">持仓</div>
      <div class="content">
        <div class="left">
          <div class="top none">
            <span>投资分布</span>
            <span>2021-09-30</span>
          </div>
          <div class="line none"></div>
          <div class="bottom">
            <p class="b_1 none">
              <span class="before1 before">基金投资</span>
              <span>87.39%</span>
            </p>
            <p class="b_2 none">
              <span class="before2 before">银行存款</span>
              <span>7.11%</span>
            </p>
            <p class="b_3 none">
              <span class="before3 before">债券</span>
              <span>5.4%</span>
            </p>
            <p class="b_4 none">
              <span class="before4 before">其他</span>
              <span>0.1%</span>
            </p>
          </div>
        </div>
        <div class="right">
          <div class="chart" id="chart3"></div>
        </div>
      </div>
    </div>
    <div class="card14 card animate__animated" v-show="currentIndex == 14">
      <div class="title">基金持仓</div>
      <div class="header none">
        <span>前十持仓占比</span>
        <div>
          合计<span><i id="number_card14">49.07</i>%</span>
        </div>
      </div>
      <div class="chart" id="chart4"></div>
    </div>
    <div class="card15 card animate__animated" v-show="currentIndex == 15">
      <div class="title none" id="title">业绩评价</div>
      <div id="chart15"></div>
    </div>
    <div class="card16 card animate__animated" v-show="currentIndex == 16">
      <div class="card16_title none">背后管理人及团队</div>
    </div>
    <div class="card17 card animate__animated none" v-show="currentIndex == 17">
      <div class="card17_title">XX证券财富优选一年持有FOF投资经理</div>
      <div class="manager none">
        <img src="../../assets/img/zx_manager.png" alt="" />
      </div>
      <div class="manager_name none">陈晓非</div>
      <ul class="exp_box none">
        <li class="li1">
          累计任职时间：<span><span id="ani_num">139</span>天</span>
        </li>
        <li class="li2">任职起始日期：<span>2021-07-22</span></li>
        <li class="li3">任职基金公司：<span>中信证券股份有限公司</span></li>
      </ul>
      <div class="box2">
        <div class="left none">
          <div class="left_title">现任基金资产</div>
          <div class="left_title2" style="margin-bottom: 8px">总规模</div>
          <div class="amount color_grown">
            <span id="ani_num2">10.6</span>亿元
          </div>
        </div>
        <div class="right none">
          <div class="right_title">任职期间最佳基金</div>
          <div class="right_title2" style="margin-bottom: 21px">回报率</div>
          <div class="amount color_grown1">
            <span id="ani_num3">10.09</span>%
          </div>
        </div>
      </div>
      <div class="thumbs_box none">
        <img src="../../assets/img/thumbs_up.png" alt="" />
      </div>
    </div>
    <div class="card18 card animate__animated" v-show="currentIndex == 18">
      <div class="content_box">
        <div class="cup">
          <div class="cup_box cup1 none">
            <img src="../../assets/img/zx_cup2.png" alt="" />
            <div class="text4">金牛理财产品评选</div>
          </div>
          <div class="cup_box cup2 none">
            <img src="../../assets/img/zx_cup2.png" alt="" />
            <div class="text1">东方财富风云榜评选</div>
          </div>
          <div class="cup_box cup3 none">
            <img src="../../assets/img/zx_cup2.png" alt="" />
            <div class="text3">中国财富管理机构君鼎奖</div>
          </div>
          <div class="cup_box cup4 none">
            <img src="../../assets/img/zx_cup2.png" alt="" />
            <div class="text2">英华奖</div>
          </div>
        </div>
        <div class="text_box"></div>
      </div>
    </div>
    <div class="card19 card animate__animated" v-show="currentIndex == 19">
      <div class="content_box">
        <div class="code_box none">
          <img src="../../assets/img/QRCode.png" alt="" />
        </div>
        <div class="box2 none">
          <div class="text">
            扫一扫
            <div>了解更多信息</div>
          </div>
          <div class="button_box">下载XX证券</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, numberRoll } from "@/utils/common";
import ApiServe from "@/api/index";
export default {
  data: function () {
    return {
      currentIndex: 1,
      cardData: {},
      card5LiData1: [],
      card5LiData2:[]
    };
  },
  computed: {
    card4ChartData() {
      if (this.cardData.chart_data_1) {
        return this.cardData.chart_data_1[0].data;
      }
      return {};
    },
    card5ChartData() {
      if (this.cardData.chart_data_3) {
        return this.cardData.chart_data_3[0].data;
      }
      return {};
    },
    card10ChartData() {
      if (this.cardData.chart_data_4) {
        return this.cardData.chart_data_4[0].data;
      }
      return {};
    },
    card12ChartData() {
      if (this.cardData.chart_data_2) {
        return this.cardData.chart_data_2[0].data;
      }
      return {};
    },
  },
  async mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let params = {
        model_id: 59,
      };
      ApiServe.getDataPC(params).then(({ data, code }) => {
        if (code == 200) {
          this.cardData = JSON.parse(JSON.stringify(data));
          this.card5LiData1 =
            data.chart_data_3[0].data["累计收益率1"].splice(0, 10) || [];
          this.card5LiData2 =
            data.chart_data_3[0].data["累计收益率2"].splice(0, 10) || [];
          this.playCardStart();
        }
      });
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.$refs.bgAudio.volume = 0.1;
      this.$refs.bgAudio.play();
      this.currentIndex = 1;
      this.playIn("fadeIn", "fadeOut", "#text1");
      await sleep(3000);
      $(".card1").addClass("animate__zoomOut");
      await sleep(1000);
      this.currentIndex = 2;
      this.$refs.audio1.play();
      $(".fofimg_box").addClass("animate__animated animate__backInDown");
      $(".logo_box").addClass("animate__animated animate__backInUp");
      await sleep(4000);
      $(".card2").addClass("animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 3;
      $('.card3 #chart1').removeClass('none')
      this.loadChart1();
      await sleep(1000)
      this.$refs.audio2.play();
       $(".card3 .header").removeClass('none')
      $(".card3 .header").addClass("animate__animated animate__bounceInDown");
      await sleep(1000)
      numberRoll('number_card3',13000,0)
      await sleep(3500);
      $(".card3").addClass("animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 4;
      this.$refs.audio4_2.play()
      $(".card4 span").eq(0).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(0).animate({ left: "158px", top: "56px" }, 1000);
      $(".card4 span").eq(1).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(1).animate({ left: "425px", top: "2px" }, 1000);
      $(".card4 span").eq(2).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(2).animate({ left: "-48px", top: "232px" }, 1000);
      $(".card4 span").eq(3).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(3).animate({ left: "446px", top: "133px" }, 1000);
      await sleep(800);
      $(".card4 .bg_img").addClass("img_rate");
      $(".card4 span").eq(4).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(4).animate({ left: "126px", top: "234px" }, 800);
      $(".card4 span").eq(5).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(5).animate({ left: "375px", top: "255px" }, 800);
      $(".card4 span").eq(6).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(6).animate({ left: "728px", top: "285px" }, 800);
      $(".card4 span").eq(7).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(7).animate({ left: "-80px", top: "362px" }, 800);
      await sleep(600);
      $(".card4 span").eq(8).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(8).animate({ left: "46px", top: "371px" }, 600);
      $(".card4 span").eq(9).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(9).animate({ left: "558px", top: "391px" }, 600);
      $(".card4 span").eq(10).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(10).animate({ left: "-80", top: "467px" }, 600);
      $(".card4 span").eq(11).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(11).animate({ left: "254px", top: "510px" }, 600);
      await sleep(400);
      $(".card4 span").eq(12).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(12).animate({ left: "479px", top: "515px" }, 400);
      $(".card4 span").eq(13).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(13).animate({ left: "30px", top: "613px" }, 400);
      $(".card4 span").eq(14).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(14).animate({ left: "247", top: "651px" }, 400);
      $(".card4 span").eq(15).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(15).animate({ left: "703px", top: "606px" }, 200);
      $(".card4 span").eq(16).animate({ opacity: 1 }, 100);
      $(".card4 span").eq(16).animate({ left: "446", top: "700px" }, 200);
      await sleep(500);
      $('.card4 .sign').addClass('sign_after')
      await sleep(1500); 
      $(".card4").addClass("animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 5; 
      $(".card5 .header").addClass("animate__animated animate__zoomInDown");
      await sleep(1000);
      $(".card5 .left").removeClass("none");
       $(".card5 .left").addClass("animate__animated animate__fadeInLeft");
      this.loadChart5()
      this.$refs.audio3.play();
      await sleep(2000)
      this.card5LiData1.forEach((item,index)=>{
         numberRoll('li'+index,item)
      })
      numberRoll('number_per1',289.04)
      await sleep(2000)
      $(".card5 .right").removeClass("none");
      $(".card5 .right").addClass("animate__animated animate__fadeInRight");
      this.loadChart6()
      await sleep(2000)
      this.card5LiData2.forEach((item,index)=>{
         numberRoll('li2'+index,item)
      })
      numberRoll('number_per2',51.43)
      await sleep(2000)
      $('.card5 .sign').addClass('sign_after')
      await sleep(4000) 
      $(".card5").addClass("animate__animated animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 6;
      $(".card6 .main_span").addClass("animate__animated animate__rotateIn");
      this.$refs.audio4.play();
      await sleep(1000);
      $(".card6 .left_span").removeClass("none");
      $(".card6 .left_span").addClass("animate__animated animate__backInLeft");
      await sleep(1000) 
      $(".card6 .right_span").removeClass("none");
      $(".card6 .right_span").addClass("animate__animated animate__bounceInRight");
      await sleep(1000)
      $(".card6 .main_span").removeClass("animate__rotateIn");
      $(".card6 .main_span").addClass("animate__heartBeat");
      await sleep(1500);
      $(".card6").addClass("animate__animated animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 7;
      $(".card7 .header").addClass("animate__animated animate__zoomInDown");
      this.$refs.audio5.play();
      await sleep(1000);
      $(".card7 .chart").removeClass("none");
      this.loadChart2();
      await sleep(600)
      $(".card7 .tips").removeClass("none")
      $(".card7 .tips").addClass("animate__animated animate__zoomIn")
      await sleep(1000);
      $('.card7 .sign').addClass('sign_after')
      await sleep(2000) 
      $(".card7").addClass("animate__animated animate__fadeOut");   
      await sleep(1000); 
      this.currentIndex = 8;
      $(".card8 #text7").addClass("animate__animated animate__backInUp");
      this.$refs.audio6.play();
      await sleep(1500);
      $(".card8").addClass("animate__animated animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 9;
      $(".card9 .text").addClass("animate__animated animate__flipInX");
      // this.$refs.audio7.play();
      await sleep(1500);
      $(".card9").addClass("animate__animated animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 10;
      $(".card10 .text1").addClass(
        "animate__animated animate__lightSpeedInLeft"
      );
      this.$refs.audio8.play();
      await sleep(2000)
      $(".card10 .hg").removeClass('none')
      $(".card10 .hg").addClass(
        "animate__animated animate__heartBeat"
      );
      await sleep(1500); 
      $(".card10").addClass("animate__animated animate__fadeOut");
      await sleep(1000);
        this.currentIndex = 11;
        $('.card11 .header').addClass("animate__animated animate__zoomInDown");
        this.$refs.audio9.play();
        await sleep(1000)
        $('.card11 .tab_box').removeClass("none");
        $('.card11 .tab_box').addClass("animate__animated animate__zoomInLeft");
        await sleep(1000)
        $('.card11 .line').removeClass("none");
        $('.card11 .article_box').removeClass("none");
        $('.card11 .left').addClass("animate__animated animate__zoomInLeft");
        $('.card11 .right').addClass("animate__animated animate__zoomInRight");
        await sleep(1000)
        numberRoll('number4',5939.50)
        await sleep(2500)
        numberRoll('number1',10.09)
        await sleep(2000)
        numberRoll('number2',0.58)
        await sleep(2000)
        numberRoll('number3',1.5495,4)
        await sleep(2500) 
      $(".card11").addClass("animate__animated animate__fadeOut");
      await sleep(1000); 
      this.currentIndex = 12;
      this.$refs.audio12.play();
      $(".title").removeClass("none");
      $(".title").addClass("animate__animated animate__backInLeft");
      await sleep(1000);
      this.loadChart12();
      await sleep(10000);
      
      $(".card12").addClass("animate__animated animate__fadeOut");
      await sleep(1000);
        this.currentIndex = 13;
        this.$refs.audio13.play();
        $('.card13 .header').addClass("animate__animated animate__zoomInDown");
        await sleep(1000)
        $('.card13 .top').removeClass("none");
        $('.card13 .top').addClass("animate__animated animate__zoomInLeft");
        await sleep(1000)
        $('.card13 .line').removeClass("none");
        this.loadChart3()
        await sleep(1000)
        $('.card13 .b_1').removeClass("none");
        $('.card13 .b_1').addClass("animate__animated animate__backInLeft");      
        await sleep(1000)
        $('.card13 .b_2').removeClass("none");
        $('.card13 .b_2').addClass("animate__animated animate__backInLeft");
        await sleep(1000)
        $('.card13 .b_3').removeClass("none");
        $('.card13 .b_3').addClass("animate__animated animate__backInLeft");
        await sleep(1000)
        $('.card13 .b_4').removeClass("none");
        $('.card13 .b_4').addClass("animate__animated animate__backInLeft");
        await sleep(1500)     
      $(".card13").addClass("animate__animated animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 14;
       this.$refs.audio14.play();
      $(".card14 .title").addClass("animate__animated animate__zoomInDown");
      await sleep(1000);
      $(".card14 .header").removeClass("none");
      $(".card14 .header").addClass("animate__animated animate__zoomInLeft");
      await sleep(1000);
      numberRoll('number_card14',49.07)
      await sleep(1000)
      this.loadChart4();
      await sleep(4000);
      $(".card14").addClass("animate__animated animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 15;
       this.$refs.audio15.play();
      $(".title").removeClass("none");
      this.playIn("topIn", "fadeOut", "#title");
      await sleep(1000);
      this.loadChart15();
      await sleep(2000);
      $(".card15").addClass("animate__animated animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 16;
      this.$refs.audio16.play();
      await sleep(1000);
      $(".card16_title").removeClass("none");
      $(".card16_title").addClass("animate__animated animate__bounceInDown");
      await sleep(2000);
      $(".card16").addClass("animate__animated animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 17;
      this.$refs.audio17.play();
      $(".card17_title").addClass("animate__animated animate__fadeInLeft");
      $(".card17").removeClass("none");
      await sleep(1000);
      $(".manager").removeClass("none");
      $(".manager_name").removeClass("none");
      $(".manager_name").addClass(
        "animate__animated animate__lightSpeedInRight"
      );
      await sleep(800);
      $(".exp_box").removeClass("none");
      $(".exp_box").addClass("animate__animated animate__zoomIn");
      numberRoll("ani_num", 139, 0);
      await sleep(3000);
      $(".left").removeClass("none");
      $(".left").addClass("animate__animated animate__zoomIn");
      $(".right").removeClass("none");
      $(".right").addClass("animate__animated animate__zoomIn");
      await sleep(1000);
      numberRoll("ani_num2", 10.6, 1);
      await sleep(1000);
      numberRoll("ani_num3", 10.09, 2);
      await sleep(1000);
      $(".thumbs_box").removeClass("none");
      $(".thumbs_box").addClass("animate__animated animate__rotateInDownLeft");
      await sleep(2000);
      $(".card17").addClass("animate__animated animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 18;
      this.$refs.audio18.play();
      $(".cup2").removeClass("none");
      $(".cup2").addClass("animate__animated animate__fadeInRight");
      $(".cup3").removeClass("none");
      $(".cup3").addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".cup1").removeClass("none");
      $(".cup1").addClass("animate__animated animate__fadeInLeft");
      $(".cup4").removeClass("none");
      $(".cup4").addClass("animate__animated animate__fadeInRight");
      await sleep(1000);
      $(".cup1").removeClass("animate__animated animate__fadeInLeft");
      $(".cup2").removeClass("animate__animated animate__fadeInRight");
      $(".cup3").removeClass("animate__animated animate__fadeInLeft");
      $(".cup4").removeClass("animate__animated animate__fadeInRight");
      $(".cup1").addClass("animate__animated animate__heartBeat");
      await sleep(1000);
      $(".cup2").addClass("animate__animated animate__heartBeat");
      await sleep(2000);
      $(".cup3").addClass("animate__animated animate__heartBeat");
      await sleep(2000);
      $(".cup4").addClass("animate__animated animate__heartBeat");
      await sleep(1000);
      $(".card18").addClass("animate__animated animate__fadeOut");
      await sleep(1000);
      this.currentIndex = 19;
      this.$refs.audio19.play();
      await sleep(1000);
      $(".box2").removeClass("none");
      $(".box2").addClass("animate__animated animate__lightSpeedInRight");
      await sleep(2000);
      $(".code_box").removeClass("none");
      $(".code_box").addClass("animate__animated animate__flash");
    },
    // 公募基金数量的图表
    loadChart1() {
      console.log(this.card4ChartData);
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "100px",
          top: "60px",
          bottom: "100px",
          right: "0",
        },
        legend: {
          data: ["存续基金数量", "存续股票数量"],
          top: 0,
          textStyle: {
              fontSize: 22,
              color: "#333333",
            },
        },
        animationDuration: 1000,
        xAxis: [
          {
            type: "category",
            data: this.card4ChartData["日期"],
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                color: "#9A9A9A",
              },
            },
            axisLabel: {
              color: "#9A9A9A",
              fontSize: 22,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            show: true,
            axisLabel: {
              fontSize: 22,
            },
          },
        ],
        series: [
          {
            name: "存续基金数量",
            type: "bar",
            data: this.card4ChartData["存续基金数量"],
            itemStyle: {
              color: "#4C5664",
            },
          },
          {
            name: "存续股票数量",
            type: "bar",
            data: this.card4ChartData["存续股票数量"],
            itemStyle: {
              color: "#D40100",
            },
          },
        ],
      };
      myChart.setOption(option, true);
    },
    // 公募基金募集规模的图表
    loadChart2() {
      var myChart = this.$echarts.init(document.getElementById("chart2"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "100px",
          top: "20px",
          bottom: "100px",
          right: "20px",
        },
        legend: {
          data: ["募集规模（亿元）", "Wind全A（右轴）"],
          bottom: 40,
        },
        animationDuration: 2000,
        xAxis: [
          {
            type: "category",
            data: this.card10ChartData["DATE"],
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                color: "#9A9A9A",
              },
            },
            axisLabel: {
              color: "#9A9A9A",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            show: true,
          },
        ],
        series: [
          {
            name: "募集规模（亿元）",
            type: "bar",
            data: this.card10ChartData["募集规模"],
            itemStyle: {
              color: "#4C5664",
            },
          },
          {
            name: "Wind全A（右轴）",
            type: "line",
            data: this.card10ChartData["Wind全A"],
            itemStyle: {
              color: "#D40100",
            },
          },
        ],
      };
      myChart.setOption(option, true);
    },
    // 持仓饼图
    loadChart3() {
      var myChart = this.$echarts.init(document.getElementById("chart3"));
      // 指定图表的配置项和数据
      var option = (option = {
        series: [
          {
            name: "持仓",
            type: "pie",
            radius: ["50%", "80%"],
            avoidLabelOverlap: false,
            animationDuration: 2000,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            color: ["#F5CD31", "#9A30F8", "#349AF9", "#FA6400"],
            data: [
              { value: 70, name: "基金投资" },
              { value: 20, name: "银行存款" },
              { value: 10, name: "债券" },
              { value: 5, name: "其他" },
            ],
          },
        ],
      });
      myChart.setOption(option, true);
    },
    // 基金持仓折线图
    loadChart4() {
      let myChart = this.$echarts.init(document.getElementById("chart4"));
      // 绘制图表
      myChart.setOption(
        {
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: [
              "中欧量化驱动",
              "工银瑞信新金融A",
              "银华心怡",
              "易方达新收益C",
              "海富通改革驱动",
              "富国周期优势A",
              "国富估值优势",
              "兴全绿色LOF",
              "兴全商业模式LOF",
              "中庚小盘价值",
            ],
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              rotate: -30,
              textStyle: {
                fontSize: 22,
                color: "#333333",
              },
            },
            axisLine: {
              show: true,
              onZero: false,
              lineStyle: {
                color: "#979797",
                width: 4,
              },
            },
          },
          legend: {
            show: true,
            icon: "line",
            data: ["持仓占比", "近一年涨幅"],
            top: 5,
            right: 5,
            textStyle: {
              fontSize: 26,
              color: "#333333",
            },
          },
          animationDuration: 2000,
          grid: {
            top: 60,
            left: 60,
            right: 120,
            bottom: 100,
          },
          yAxis: [
            {
              type: "value",
              show: true,
              name: "",
              boundaryGap: [0, "100%"],
              position: "left",
              axisTick: {
                show: false,
              },
              axisLabel: {
                textStyle: {
                  fontSize: 22,
                  color: "#333333",
                },
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#979797",
                  width: 4,
                },
              },
            },
          ],
          series: [
            {
              name: "持仓占比",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#006DC3",
                width: 6,
              },
              color: "#006DC3",
              data: [7.06, 5.62, 5.58, 4.73, 4.5, 4.42, 4.39, 4.27, 4.26, 4.24],
              animationDuration: 4000,
              animationEasing: "cubicInOut",
              label: {
                show: true,
              },
            },
            {
              name: "近一年涨幅",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#D40100",
                width: 6,
              },
              color: "#D40100",
              data: [
                37.95, 43.84, 55.26, 21.4, 28.48, 20.44, 18.03, 26.99, 5.27,
                58.76,
              ],
              animationDuration: 4000,
              animationEasing: "cubicInOut",
              label: {
                show: true,
              },
            },
          ],
          markLine: {
            symbol: ["none", "none"],
            label: { show: false },
            data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }],
          },
        },
        true
      );
    },
    // 近三年收益率前10
    loadChart5() {
      let myChart = this.$echarts.init(document.getElementById("chart5"));
      myChart.setOption({
        grid: {
          left: "95px",
          right: "0",
          top: "0",
          bottom: "0",
        },
        animationDuration: 2000,
        xAxis: [
          {
            type: "value",
            position: "bottom",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: 18,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            data: this.card5ChartData["近三年基金收益率前10"].splice(0, 10),
            inverse: true,
            axisLabel: {
              textStyle: {
                margin: 20,
                fontSize: 21,
                color: "#333333",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.card5ChartData["累计收益率1"].splice(0, 10),
            barWidth: 24,
            label: {
              show: false,
            },
            stack: "total",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(0,0,0,.1)",
              borderRadius: 20,
            },
            itemStyle: {
              normal: {
                color: "#FFAFAF",
              },
            },
          },
        ],
      });
    },
    // 近三年收益率后10
    loadChart6() {
      let myChart = this.$echarts.init(document.getElementById("chart6"));
      myChart.setOption({
        grid: {
          left: "95px",
          right: "0",
          top: "0",
          bottom: "0",
        },
        animationDuration: 2000,
        xAxis: [
          {
            type: "value",
            position: "bottom",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: 18,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            data: this.card5ChartData["近三年基金收益率后10"].splice(0, 10),
            inverse: true,
            axisLabel: {
              textStyle: {
                margin: 20,
                fontSize: 21,
                color: "#333333",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.card5ChartData["累计收益率2"].splice(0, 10),
            barWidth: 24,
            label: {
              show: false,
            },
            stack: "total",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(0,0,0,.1)",
              borderRadius: 20,
            },
            itemStyle: {
              normal: {
                color: "#FFAFAF",
              },
            },
          },
        ],
      });
    },
    //财富优选一年持有
    loadChart12() {
      let myChart = this.$echarts.init(document.getElementById("chart12"));
      // 绘制图表
      myChart.setOption(
        {
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: this.card12ChartData["DATE"],
            axisTick: {
              show: true,
              inside: true,
              length: 14,
              lineStyle: {
                color: "#979797",
                width: 4,
                height: 14,
              },
            },
            axisLabel: {
              align: "left",
              textStyle: {
                fontSize: 22,
                color: "#333333",
              },
            },
            axisLine: {
              show: true,
              onZero: false,
              lineStyle: {
                color: "#979797",
                width: 4,
              },
            },
          },
          legend: {
            show: true,
            //   type: "plain",
            icon: "line",
            data: ["xx财富优选一年持有", "业绩基准", "中证800指数"],
            bottom: -5,
            itemGap: 30,
            itemWidth: 79,
            itemHeight: 14,
            textStyle: {
              fontSize: 22,
              color: "#333333",
            },
          },
          animationDuration: 2000,
          grid: {
            top: 290,
            left: 190,
            right: 50,
            bottom: 90,
          },
          yAxis: [
            {
              type: "value",
              show: true,
              name: "",
              position: "left",
              axisTick: {
                show: true,
                inside: true,
                length: 14,
                lineStyle: {
                  color: "#979797",
                  width: 4,
                  height: 14,
                },
              },
              axisLabel: {
                textStyle: {
                  fontSize: 22,
                  color: "#333333",
                },
              },
              splitLine: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#979797",
                  width: 4,
                },
              },
            },
          ],
          series: [
            {
              name: "业绩基准",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#006DC3",
                width: 6,
              },
              color: "#006DC3",
              data: this.card12ChartData["业绩基准"].slice(0, 2000),
              animationDuration: 4000,
              animationEasing: "cubicInOut",
              label: {
                show: true,
              },
            },
            {
              name: "xx财富优选一年持有",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#D40100",
                width: 6,
              },
              color: "#D40100",
              data: this.card12ChartData["中信证券财富优选一年持有"].slice(
                0,
                2000
              ),
              animationDuration: 4000,
              animationEasing: "cubicInOut",
              label: {
                show: true,
              },
            },
            {
              name: "中证800指数",
              type: "line",
              smooth: 0.6,
              symbol: "none",
              lineStyle: {
                color: "#A6A6A6",
                width: 6,
              },
              color: "#A6A6A6",
              data: this.card12ChartData["中证800指数"].slice(0, 2000),
              animationDuration: 4000,
              animationEasing: "cubicInOut",
              label: {
                show: true,
              },
            },
          ],
          markLine: {
            symbol: ["none", "none"],
            label: { show: false },
            data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }],
          },
        },
        true
      );
    },
    //业绩评价雷达图
    loadChart15() {
      var myChart = this.$echarts.init(document.getElementById("chart15"));
      var option = {
        radar: [
          {
            name: {
              textStyle: {
                color: "#C4121C",
                backgroundColor: "#fff",
                borderRadius: 3,
                fontSize: 30,
                fontWeight: 500,
              },
            },
            indicator: [
              {
                name: "选证能力",
                max: 6500,
                axisLabel: { show: true, fontSize: 20 },
              },
              { name: "择时能力", max: 16000 },
              { name: "稳定性", max: 30000 },
              { name: "抗风险", max: 38000 },
              { name: "收益率", max: 52000 },
            ],
            center: ["55%", "50%"],
            nameGap: 25,
            axisTick: {
              show: true,
              lineStyle: {
                color: "#FFC1C1",
              },
            },
            axisLine: {
              // 坐标轴轴线相关设置。
              lineStyle: {
                color: "#FFC1C1",
                width: 2.5,
              },
            },

            splitLine: {
              // 坐标轴在 grid 区域中的分隔线
              lineStyle: {
                color: "#FFC1C1",
                width: 2.5,
              },
            },
            splitArea: {
              // 坐标轴在 grid 区域中的分隔区域 (背景色)
              show: true,
              areaStyle: { color: ["#fff"] },
            },
          },
        ],
        animationDuration: 2000,
        series: [
          {
            name: "Budget vs spending",
            type: "radar",
            data: [
              {
                value: [5000, 14000, 28000, 26000, 42000, 21000],
                name: "Actual Spending",
                symbol: "none",
                lineStyle: {
                  color: "#D56268",
                  width: 5,
                },
                areaStyle: {
                  opacity: 0.25,
                  color: "#F7565F",
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option, true);
    },
  },
};
</script>
<style scoped lang="less">
@import "./index.less";
</style>